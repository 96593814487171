import React from "react"
import { ItemSlider, AnimatedSection } from "@components/shared"

import {
  reviews__list,
  reviews__list__img,
  reviews__list__item,
  reviews__list__name,
  reviews__list__text,
} from "./styles/reviews.module.scss"

const ReviewSlide = ({ author, content }) => {
  return (
    <div className={reviews__list__item}>
      <div className={reviews__list__name}>{author}</div>
      <AnimatedSection className={reviews__list__text}>
        {content}
      </AnimatedSection>
      <div className={reviews__list__img}></div>
    </div>
  )
}

const Reviews = ({ reviews }) => {
  return (
    <article className={reviews__list}>
      <ItemSlider
        items={reviews}
        Slide={ReviewSlide}
        observeValue={(item) => item.author}
      />
    </article>
  )
}

export default Reviews

import React from "react"
import { AnimatedLink, AnimatedSection } from "@components/shared"
import Reviews from "./Reviews"

import book from "./icons/gladiators3star.png"
import teaching from "./icons/teaching gladiator.png"
import chevron from "./icons/chevron-down-solid.svg"

import {
  purpose__section,
  purpose__section__title,
  purpose__section__items,
  purpose__presentation,
  purpose__presentation__icon,
  purpose__presentation__content,
  purpose__presentation__read,
  purpose__presentation__chevron,
  purpose__presentation__opinions,
  purpose__presentation__button,
  purpose__presentation__button__alt,
} from "./styles/home.module.scss"

const book_reviews = [
  {
    author: "Mateusz",
    content:
      "Ciekawe zadania, dzięki którym można utrwalić wiedzę, a przy tym poznać coś nowego ze świata JSa i Reacta",
  },
  {
    author: "Krzysztof",
    content:
      "Fajna inicjatywa 💪🏻 i ogromna ilość zadań. No to biorę się za kodowanie :)",
  },
  {
    author: "Dorota",
    content:
      "Ale trudne te zadania, uświadomiłam sobie jak niewiele umiem i jak dużo pracy jeszcze mnie czeka",
  },
  {
    author: "Michał",
    content:
      "Super inicjatywa. I ceny widzę też bardzo przystępne. Zadania ciekawe, bardzo fajne oderwanie od codziennych zajec na projekcie",
  },
]

const glad_reviews = [
  {
    author: "Michał",
    content:
      "Współpraca z Przemkiem pozwoliła mi na chwilę oderwać się od mało rozwojowego projektu i przeciwstawić stagnacji. Podczas 2 miesięcznych spotkań zacząłem doceniać wartość testów jednostkowych, ustrukturyzowałem wiedzę z JS’a jak również poznałem wiele wzorców projektowych. Każde spotkanie dawało mi dużego kopa motywacyjnego. Polecam.",
  },
  {
    author: "Emil",
    content:
      "Porównując siebie sprzed i po dołączeniu do gladiatorów to mogę przyznać, że nauczyłem się więcej niż się spodziewałem. Teoretycznie posiadałem już jakąś wiedzę ale Przemek udowadniał mi za każdym razem, że jednak nie do końca. Bardzo dużo się nauczyłem przez te 2 miesiące, największym sukcesem myślę, że jest nauczenie się TypeScripta oraz nauczenie się pisania testów kodu. Polecam każdemu, kto chce dodać mocny improve w swoją naukę kodowania :fire:",
  },
  {
    author: "Piotr",
    content:
      "Muszę przyznać, że pierwszy raz brałem udział w takiej inicjatywie jak Gladiatorzy JavaScriptu i nie wiedziałem czego się spodziewać. Zaskoczyłem się bardzo miło – okazało się, że formuła rozwiązywania zadań oraz wszelkiej pomocy, zarówno od Przemka jak i od innych osób z grupy zdaje egzamin. Spotkania code review pozwalały spojrzeć szerzej na omawiany problem, a dodatkowo wtrącane przez Przemka smaczki i ciekawostki czyniły ten czas bardzo wartościowym. Polecam wszystkim, którzy chcą podnieść swoje umiejętności kodowania, zrozumieć jak programowanie łączy się z biznesem, a także dla tych, którzy szukają nowych inspiracji.",
  },
]

const PurposePresentation = ({
  icon,
  content,
  read,
  ctaText,
  btnClass = purpose__presentation__button,
  to,
  reviews,
}) => {
  return (
    <div className={purpose__presentation}>
      <AnimatedSection className={purpose__presentation__icon}>
        <img src={icon} alt="icon" />
      </AnimatedSection>
      <div className={purpose__presentation__content}>{content}</div>
      <div className={purpose__presentation__read}>{read}</div>
      <div className={purpose__presentation__chevron}>
        <img src={chevron} alt="" />
      </div>
      <AnimatedSection className={purpose__presentation__opinions}>
        <Reviews reviews={reviews} />
      </AnimatedSection>
      <AnimatedSection>
        <AnimatedLink to={to} className={btnClass}>
          {ctaText}
        </AnimatedLink>
      </AnimatedSection>
    </div>
  )
}

const PurposePresentationsSection = () => {
  return (
    <section className={purpose__section}>
      <h3 className={purpose__section__title}>
        Aby wskazać Ci właściwy <strong>kierunek</strong> do dalszego{" "}
        <strong>rozwoju</strong>, stworzyłem <strong>2 inicjatywy</strong> dla
        młodych programistów:
      </h3>
      <div className={purpose__section__items}>
        <PurposePresentation
          icon={teaching}
          to={"/gladiators/"}
          reviews={glad_reviews}
          read={
            "Poczytaj opinie osób, które uczestniczyły w Gladiatorach lub regularnie chodzą na zajęcia:"
          }
          content={
            <span>
              Trenuję GLADIATORÓW JAVASCRIPTU oraz prowadzę zajęcia z
              programowania LIVE CODE REVIEW
            </span>
          }
          ctaText={"Zacznij trening"}
        />
        <PurposePresentation
          icon={book}
          read={
            "Poczytaj opinie osób, które wsparły inicjatywę i mają swoje zdanie na temat moich zbiorów:"
          }
          reviews={book_reviews}
          content={
            <span>
              Tworzę <strong>ZBIORY ZADAŃ DLA PROGRAMISTÓW</strong> z języka
              Javascript
            </span>
          }
          ctaText={"Zajrzyj do biblioteki"}
          to={"/shop/"}
          btnClass={[
            purpose__presentation__button,
            purpose__presentation__button__alt,
          ].join(" ")}
        />
      </div>
    </section>
  )
}

export default PurposePresentationsSection

import React from "react"
import {AnimatedSection} from '@components/shared'
import {
    gladiators__benefit,
    gladiators__benefit__item
} from './styles/gladiators.module.scss'

const SingleBenefit = ({label, effect}) => {
    return (
        <AnimatedSection>
            <div className={gladiators__benefit}>
                <div className={gladiators__benefit__item}>{label}</div>
                <div className={gladiators__benefit__item}>
                <i class="fas fa-chevron-right"></i>
                </div>
                <div className={gladiators__benefit__item}>{effect}</div>
            </div>
        </AnimatedSection>
    )
}

export default SingleBenefit
import React from "react"
import { body__main, body__wrapper } from "./styles/home.module.scss"

import TargetingSection from "./TargetingSection"
import PurposePresentationsSection from "./PurposePresentationsSection"

const HomeSection = () => {
  return (
    <div className={body__main}>
      <div className={body__wrapper}>
        <TargetingSection />
        <PurposePresentationsSection />
      </div>
    </div>
  )
}

export default HomeSection

import { GoogleApi } from "@api"

const retrieveEvents = () => {
  return GoogleApi.getCallendarEvents().then((data) => {
    console.log("dataaa", data)
    return data
  })
}

const addEvent = (startDate, endDate, title) => {
  return GoogleApi.addCalendarEvent(startDate, endDate, title).then((data) => {
    console.log("response", data)
  })
}

export { retrieveEvents, addEvent }

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ResizedImage as Image } from '@components/shared'

const Avatar = () => {
    const { WP } = useStaticQuery(graphql`
    query {
        WP {
            mediaItemBy(slug: "przemek") {
                slug
                sourceUrl
                srcSet
                uri
                }
            }
        }
    `)
    return <Image {...WP.mediaItemBy} size={'300w'} />
}

export default Avatar